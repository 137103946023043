<template>
  <div>
    <b-row>
    <b-col></b-col>
    <b-col>


      <div class="theLoginForm">
        <div class="theLoginForm__heading"></div>

        <b-form v-if="!isPasswordReset" class="theLoginForm__form" @submit.prevent="resetPassword">
        
          <h4 class="theLoginForm__resetPsw--title">{{ $t("password.reset.label") }}</h4>
          
          <p>{{ $t("password.reset.message") }}.</p>
          <div>
            <b-form-input class="theLoginForm__form__email" type="password" placeholder="New Password" v-model="password" required></b-form-input>
            <b-form-invalid-feedback :state="validation">{{ $t("password.reset.validation") }}</b-form-invalid-feedback>
            <b-form-valid-feedback :state="validation"></b-form-valid-feedback>
          </div>
          <b-form-input class="theLoginForm__form__email"  type="password" v-model="confirmPassword" placeholder="Confirm Password" required></b-form-input>

          <button class="primary" type="submit">{{ $t("password.reset.label") }}</button>
          <p v-if="resetError=='400'">{{ $t("password.reset.tooeasy")  }}</p>
          <p v-if="resetError=='not match'">{{ $t("password.reset.notvalid")  }}</p>
        </b-form> 

      <!-- Maiuscola minuscola numero !@#$%^&* -->
        <div v-if="isPasswordReset">
          
          <div class="theLoginForm__resetPsw--resetPswDone">
            <h4 class="theLoginForm__resetPsw--title">{{ $t("password.reset.title") }}</h4>
            <div>
              <p>{{ $t("password.reset.done") }}</p>
            </div>
            <button class="primary" @click="backToLogin">{{ $t("password.reset.continue") }}</button>

          </div>
        </div>
      </div>
      

    </b-col>
    
    <b-col></b-col>
    </b-row>
    
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      resetError: '',
      isPasswordReset: false
    };
  },
  computed: {
      validation() {
        return this.password.length > 7 && this.password.length < 13
      }
  },
  methods: {
    async resetPassword() {
      if (this.password !== this.confirmPassword) {
        this.resetError = 'not match';
        return;
      }
      try {
        if(this.password.length > 7 && this.password == this.confirmPassword){
           // Effettua la chiamata API per reimpostare la password
          await axios.post('/user/reset-password', {   newPassword: this.password, token: this.$route.params.jwt }).then( res=>{
            if(res.status === 200){
              this.isPasswordReset = true
            }
          });
        }
       
      } catch (error) {
        if(error.response.status === 400){
            this.resetError = '400';
        }
      }
    },
    backToLogin(){
      this.$router.push("/login");
    }
  },
};
</script>